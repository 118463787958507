











































import { FilterConfig } from '@/views/chat/filters/FilterTypes'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import Vue from 'vue'
import { Emit, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

@Component({
  components: {
    Icon,
    Tags
  },
  data() {
    return {
      getTariffTranslationKey
    }
  }
})
export default class FilterCard extends Vue {
  @Prop() filterObject!: FilterConfig

  @Prop({ type: Boolean, default: false }) removable!: boolean

  @Prop() updateListKey!: number

  @Emit('select')
  onSelectButtonClick(): void {
  }

  @Emit('remove')
  onRemoveButtonClick(): void {
  }

  popupActive = false

  get disabled() {
    return [ 'permitted_word', 'regular_expression', 'emoji' ].includes(this.filterObject.type)
      ? !this.$store.state.chatState.chat.limits.options.includes(`filter_${ this.filterObject.type }`) : false
  }

  handleRemoveFilterButtonClick():void {
    this.$confirm({
      title: this.$t('filter_removable_dialog_title').toString(),
      content: this.$t('filter_removable_dialog_text').toString(),
      okText: this.$t('filter_removable_accept').toString(),
      okType: 'danger',
      cancelText: this.$t('filter_removable_reject').toString(),
      centered: true,
      onOk: () => this.onRemoveButtonClick()
    })
  }
}
