import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.HieroglyphMessage,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'HieroglyphMessage',
      textExamples: [
        '我們以小繁體為例。',
        '我们以小简体中文为例。'
      ]
    }
  },
  icon: {
    name: "translate",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/HieroglyphMessage/HieroglyphMessageView.vue'),
  advancedFieldNames: [ 'hieroglyph_message_max_percent' ]
} as FilterConfig
