import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Voice,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Voice',
      imgExamples: [ require('./Voice.png') ]
    }
  },
  icon: {
    name: "voice_chat",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Voice/VoiceView.vue'),
  advancedFieldNames: [
    'voice_max_duaration',
    'voice_max_file_size',
    'voice_min_duaration',
    'voice_min_file_size',
  ]
} as FilterConfig
