import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.VideoNote,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'VideoNote',
      imgExamples: [ require('./VideoNote.png') ]
    }
  },
  icon: {
    name: "voice_chat",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/VideoNote/VideoNoteView.vue'),
  advancedFieldNames: [
    'video_note_max_duaration',
    'video_note_max_file_size',
    'video_note_min_duaration',
    'video_note_min_file_size',
  ]
} as FilterConfig
