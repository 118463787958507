import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Gif,
  description: i18n.t('gif_message_filter_example_view_text').toString(),
  icon: {
    name: "gif",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Gif/GifView.vue'),
  advancedFieldNames: [
    'gif_max_duaration',
    'gif_max_file_size',
    'gif_max_height',
    'gif_max_width',
    'gif_min_duaration',
    'gif_min_file_size',
    'gif_min_height',
    'gif_min_width',
  ]
} as FilterConfig
