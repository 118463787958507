import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.StickerPack,
  description: i18n.t('sticker_pack_message_filter_example_view_text').toString(),
  icon: {
    name: "sentiment_satisfied_alt",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/StickerPack/StickerPackView.vue'),
  advancedFieldNames: [
      'sticker_pack_items'
  ]
} as FilterConfig
