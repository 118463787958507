import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.BotCommand,
  exampleView: {
    component:() => import('../exampleView.vue'),
    propsData: {
      filterKey: 'BotCommand',
      textExamples: [
        '/mute',
        '/bottomrep',
        '/lasttoprep 02.03.2022-02.05.2022'
      ]
    }
  },
  icon: {
    name: "developer_mode",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/BotCommand/BotCommandView.vue'),
  advancedFieldNames: [
      'bot_commands_strict_mode',
      'bot_commands_whitelist'
  ]
} as FilterConfig
