


























































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import FiltersListHelper from '@/views/chat/filters/FiltersListHelper'
import FilterCard from '@/views/chat/filters/FilterCard.vue'
import { FilterConfig, FilterTypes } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '../../../mixins/input-setups'

import { Item } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ListWithPreview from 'piramis-base-components/src/components/ListWithPreviews/ListWithPreviews.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins } from 'vue-property-decorator'
import { isEmpty } from 'lodash'

@Component({
  components: {
    FilterCard,
    CenteredColumnLayout,
    PageTitle,
    ListWithPreview,
    EmptyData
  },
  data() {
    return {
      isEmpty
    }
  }
})
export default class New_filters extends Mixins<UseFields, InputSetups, FiltersListHelper>(UseFields, InputSetups, FiltersListHelper) {
  isAddFilterPopupActive = false

  updateListKey = 1

  selectFromDisabledList(item: Item): void {
    this.$store.commit('setObjectField', {
      object: this,
      objectPath: `$store.state.chatState.chat.config.${ item.key }.enabled`,
      value: true,
    })

    this.isAddFilterPopupActive = false
    this.updateListKey += 1
  }

  removeFilter(filterItem: FilterConfig): void {
    this.$store.commit('setObjectField', {
      object: this,
      objectPath: `$store.state.chatState.chat.config.${ filterItem.type }.enabled`,
      value: false,
    })
    this.updateListKey += 1
  }

  gotoSelectedFilter(filterType: FilterTypes): void {
    this.$router.push({
      name: 'filter',
      params: {
        [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
        filterName: filterType.toString()
      }
    })
  }
}

